import { LayoutType } from '@/shared/types/generic';
import { ResponseLogin } from '@/shared/types/token';

export default (): ResponseLogin => ({
  publicDisplayLayout: LayoutType.ALL_CHALLENGES,
  accessToken: 'lskdjfsdf-sdjflskdjfds-fksdjflsj-ssioaaiou',
  teamReference: '2222-22344-44555',
  languageCode: 'pt-PT',
  organizationName: 'City',
  publicDisplayReference: 'd099cdc0-b1e4-f386-5201-2aa3cd9408f1',
});
