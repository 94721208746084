import axios from '@/plugins/http/axios';
import {
  OrganizationDisplay,
  OrganizationDisplaysResponse,
} from '@/shared/types/organization';

const getOrganizationDisplays = async (
  orgName: string,
): Promise<OrganizationDisplaysResponse> => {
  return axios.get(`/public-displays/displays`, {
    params: {
      orgName,
    },
  });
};

const getOrganizationDisplayByDisplayCode = async (
  displayCode: string,
): Promise<OrganizationDisplay> => {
  return axios.get(`/public-displays`, {
    params: {
      displayCode,
    },
  });
};

export default {
  getOrganizationDisplays,
  getOrganizationDisplayByDisplayCode,
};
