import { GREEN_500, BLUE_500 } from '@/shared/constants/colors';
import { ThemesResponse } from '@/shared/types/themes';

function getRandom(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
// '/img/vaibe-logo.svg'
// 'https://github.com/favicon.ico'
export const mockThemes = (): Array<ThemesResponse> => {
  return [
    {
      logo: {
        default: false,
        description: '',
        name: '',
        reference: '',
        url: '/img/vaibe-logo.svg',
      },
      name: '',
      opacity: 0,
      favicon: {
        default: false,
        description: '',
        name: '',
        reference: '',
        url: 'https://github.com/favicon.ico',
      },
      publicDisplayBackground: {
        default: false,
        description: '',
        name: '',
        reference: '',
        url: '/img/BGCocaCola.svg',
      },
      reference: '',
      primaryColor: {
        colorHex: GREEN_500,
        colorRgb: GREEN_500,
      },
      secondaryColor: {
        colorHex: BLUE_500,
        colorRgb: BLUE_500,
      },
    },
  ];
};

export const getTheme = (): ThemesResponse => {
  const randomValue = getRandom(0, mockThemes().length - 1);
  return mockThemes()[randomValue];
};
