import { defineStore } from 'pinia';
import { reactive } from 'vue';
import themesService from '@/shared/services/themes/themesService';
import { ImageResponse, ThemesResponse } from '@/shared/types/themes';
import { useRouter } from 'vue-router';

export default defineStore('themes', () => {
  const state = reactive<ThemesResponse>({
    logo: {} as ImageResponse,
    name: '',
    opacity: 0,
    favicon: {} as ImageResponse,
    publicDisplayBackground: {} as ImageResponse,
    reference: '',
    primaryColor: {
      colorHex: '',
      colorRgb: '',
    },
    secondaryColor: {
      colorHex: '',
      colorRgb: '',
    },
  });

  function changeFavicon(url: string): void {
    let link: HTMLLinkElement | null =
      document.querySelector("link[rel*='icon']");

    if (!link) {
      link = document.createElement('link');
      link.rel = 'shortcut icon';
      document.head.appendChild(link);
    }

    link.type = 'image/x-icon';
    link.href = url;
  }

  async function loadSettingsThemes(): Promise<void> {
    const {
      logo,
      favicon,
      primaryColor,
      secondaryColor,
      publicDisplayBackground,
    } = await themesService.getThemes();

    state.primaryColor = primaryColor;
    state.secondaryColor = secondaryColor;
    state.logo = logo;
    state.favicon = favicon;
    state.publicDisplayBackground = publicDisplayBackground;

    document.documentElement.style.setProperty(
      '--primary-color',
      primaryColor.colorHex,
    );

    // NOTE: primary background only at manager app

    document.documentElement.style.setProperty(
      '--secondary-color',
      secondaryColor.colorHex || primaryColor.colorHex,
    );

    changeFavicon(state.favicon.url);
  }

  async function loadSettingsThemesByOrgName(orgName: string): Promise<void> {
    const router = useRouter();

    try {
      const { logo, favicon, primaryColor, secondaryColor, opacity } =
        await themesService.getThemesOrg(orgName);

      state.primaryColor = primaryColor;
      state.secondaryColor = secondaryColor;
      state.logo = logo;
      state.favicon = favicon;

      document.documentElement.style.setProperty(
        '--primary-color',
        primaryColor.colorHex,
      );

      document.documentElement.style.setProperty(
        '--primary-background',
        `rgba(${primaryColor.colorRgb},${opacity})`,
      );

      document.documentElement.style.setProperty(
        '--secondary-color',
        secondaryColor.colorHex || primaryColor.colorHex,
      );

      changeFavicon(state.favicon.url);
    } catch (error) {
      router.push('/404');
    }
  }

  return {
    state,
    loadSettingsThemes,
    loadSettingsThemesByOrgName,
  };
});
