import { computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import { OrganizationDisplay } from '@/shared/types/organization';
import organizationService from '@/shared/services/organization/organizationService';

interface State {
  organizationName: string;
  organizationDisplays: OrganizationDisplay[];
  selectedDisplay?: OrganizationDisplay;
}

export default defineStore('organization', () => {
  const state = reactive<State>({
    organizationName: '',
    organizationDisplays: [],
    selectedDisplay: undefined,
  });

  const orgNameComputed = computed(
    () => state.selectedDisplay?.orgName || state.organizationName,
  );

  const loadOrganizationDisplays = async (orgName: string): Promise<void> => {
    try {
      state.organizationDisplays = (
        await organizationService.getOrganizationDisplays(orgName)
      ).displays;
    } catch (e) {
      state.organizationDisplays = [];
    }
  };

  const selectDisplay = (display: OrganizationDisplay) => {
    state.selectedDisplay = display;
  };

  const loadOrganizationDisplayByDisplayCode = async (displayCode: string) => {
    try {
      state.selectedDisplay =
        await organizationService.getOrganizationDisplayByDisplayCode(
          displayCode,
        );
    } catch (e) {
      state.selectedDisplay = undefined;
    }
  };

  const hasOnlyOneDisplay = computed(
    () => state.organizationDisplays.length === 1,
  );
  return {
    state,
    loadOrganizationDisplays,
    loadOrganizationDisplayByDisplayCode,
    selectDisplay,
    hasOnlyOneDisplay,
    orgNameComputed,
  };
});
