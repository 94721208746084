import { rest } from 'msw';
import basePath from '@/mocks/helpers/basePath';
import mockDailyProductivity from './publicDisplays/mockDailyProductivity';
import mockOverallMetrics from './overallMetrics/mockOverallMetrics';
import mockChallengeOfTheDay from './challengeOfTheDay/mockChallengeOfTheDay';
import mockProductivityByTeam from './productivityByTeam/mockProductivityByTeam';
import mockTeam from './team/mockTeam';
import mockLogin from './login/mockLogin';
import mockIndividualChallenges from './individual/mockIndividualChallenges';
import { challengesResponse } from './challenges/mockChallenges';
import { mockOrganizatioDisplaysResponse } from './organization/mockOrganization';
import { getTheme } from './themes/mockThemes';

const handlers = [
  rest.get(
    basePath('/public-displays/:reference/challenges'),
    (_, res, ctx) => {
      return res(ctx.status(200), ctx.json(challengesResponse()));
    },
  ),

  rest.get(
    basePath('/public-displays/global-productivity-chart'),
    (_, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockDailyProductivity(5)));
    },
  ),

  rest.get(
    basePath('/public-displays/global-productivity-last-days'),
    (_, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockOverallMetrics()));
    },
  ),

  rest.get(
    basePath('/public-displays/day-challenge/:reference'),
    (_, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockChallengeOfTheDay()));
    },
  ),

  rest.get(
    basePath('/public-displays/team-productivity-charts'),
    (_, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(mockProductivityByTeam(Math.floor(Math.random() * 10))),
      );
    },
  ),
  rest.get(basePath('/public-displays/team/:reference'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockTeam()));
  }),

  rest.post(basePath('/management/public-displays/login'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockLogin()));
  }),

  rest.get(
    basePath('/management/public-displays/authentication/:organizationName'),
    (_, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockLogin()));
    },
  ),

  rest.get(
    basePath('/public-displays/individual/challenges'),
    (_, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockIndividualChallenges()));
    },
  ),

  rest.get(
    basePath('/public-displays/displays?organizationName=organizationName'),
    (_, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockOrganizatioDisplaysResponse));
    },
  ),
  rest.get(basePath('/public-displays/theme'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getTheme()));
  }),
];

export default handlers;
